<template>
  <div class="pageBox">
    <div class="page-list personnelfiles flex-1">
      <a-row
        class="filter-wrap  "
        style="padding-bottom: 14px;"
      >
        <!-- 复合搜索框 -->
        <ComposeInput
          :selectArr="selectArr"
          placeholderValue="请输入商品名称"
          @getList="getListSearch"
        ></ComposeInput>
        <a-button
          @click="bantchGiveup"
          class="class_btn4 canclezs pointer btn-padding"
          v-if="table.selectedRowKeys&&table.selectedRowKeys.length"
        >取消赠送</a-button>
        <span
          class="checkedZs  btn-padding"
          v-if="table.selectedRowKeys&&table.selectedRowKeys.length"
        >已选 <span class="baseColor11">{{ table.selectedRowKeys.length }}</span> 条数据</span>
      </a-row>
      <a-table
        class="table-manage flex-1"
        @change="tableChanged"
        :pagination="table.pagination"
        row-key="goods_id"
        :loading="table.loading"
        :columns="config.plan_gifts.columns"
        :data-source="table.tableData"
        :scroll="{  y:getfullHeight(164)  }"
        :row-selection=" { selectedRowKeys: table.selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxPropsfn }"
        :rowClassName="setRowClass"
        :customRow="customRow"
      >
        <template
          slot="_3"
          slot-scope="action, record"
        >
          <a-tooltip>
            <template slot="title">
              {{  record.goods_desc}}
            </template>
            {{  record.goods_desc || '商品简称'}}
          </a-tooltip>
        </template>

        <template
          slot="_7"
          slot-scope="action, record"
        >
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="handleBatch('delete',record,'提示')"
          >取消赠送</a-button>
        </template>
      </a-table>
      <!-- <a-modal
        width="756px"
        wrapClassName="commonModel"
        :bodyStyle="{height: '468px'}"
        v-model="goods_specs.ischooseModehow"
        title=" 选择规格 "
        ok-text="确认"
        @ok="hideModal"
      >
        <p class="flex align-item-center justify-between ">

        </p>
      </a-modal> -->
    </div>

  </div>

</template>
<script> 
import storage from 'store'
import Msg from '@/components/mrj-menu/msg'
import { GetmanageGoodslist, GetgoodsoptionsList, CreateGoods, UpdateCustomProposal, GoodsInfo, GetgoodsbrandsoptionsList, BatchGoods } from '@/api/commodity'
import config from '../config'
import axios from 'axios'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import ComposeInput from '@/components/compose_input'
export default {
  components: { UploadImage, ShopEditor, ComposeInput },
  data() {
    return {
      pageStatus: 'list',
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        authorization: storage.get('token'),
        "Content-Type": "multipart/form-data",
      },
      selectArr: [
        { name: '商品名称', id: '1', key: 1 },
        { name: '商品码', id: '2', key: 2 },
      ],
      typestyles: {
        width: '334px',
        height: "32px"
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      query: {
        goods_name: undefined,
        goods_bn: undefined,
        limit: 10,
        page: 1,
      },
      activeStatus1: '1',//商品名称 商品码

      table: {
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: undefined,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      brandsList: [],
      treeData: [],
      selectedKeys: [],
      expandedKeys: [],
      autoExpandParent: false,
      backupsExpandedKeys: [],
      titleList: [],//分类筛选名字 //财务 
      searchValue: '',
      selectreeObj: {
        children: 'children',
        title: 'category_name',
        key: 'category_id',
        value: 'category_id'
      },
    }
  },
  async created() {
    // this.getBrands()
    this.getList()


  },
  mounted() {

  },
  methods: {
    handleMenuClick({ key }) {
      this.handleBatch(key)
      // insale  outsale  export  empower
    },
    handleMenuClickSearch({ key }) {
      console.log(key);
      // name code  
      console.log(this.activeStatus1);

    },
    // table 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      // 
      console.log(selectedRowKeys, selectedRows);
      this.table.selectedRowKeys = selectedRowKeys
      this.table.selectedRows = selectedRows
      // if (!selectedRows.length) {
      //   var isshow = false
      //   document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "none"
      //   // document.querySelector('.btn-padding').style.display = "none"
      // } else {
      //   isshow = true
      //   // document.querySelector('.btn-padding').style.display = "inline-block"
      //   document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
      // }
      // let times = setTimeout(() => {
      //   if (isshow) {

      //     // console.log(document.querySelectorAll('.ant-table-row-selected'));
      //     document.querySelectorAll('.ant-table-row-selected').forEach(el => {
      //       el.children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].children[0].style.display = "inline-block"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   } else {
      //     document.querySelectorAll('.ant-table-body .ant-table-selection-column').forEach(el => {
      //       el.children[0].children[0].style.display = "none"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   }

      // });


    },
    getCheckboxPropsfn(record) {
      return {
        props: {
          disabled: record.status_text == "已结束" // 禁选项
        }
      };
    },
    setRowClass(record) {
      // console.log(record, 'record');
      if (record.card_status == 0) {
        // return "rowClass";
        return "failRowClass";
      } if (record.card_status == 1) {
        return "successRowClass";
      } else {
        return "successRowClass";
      }

    },
    customRow(record, index) {
      return {
        on: {
          click: event => {
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
          },
          mouseenter: event => {
            // console.log(document.querySelector('.ant-table-header .ant-checkbox-inner'), "vxvxfvfxv");
            // console.log(event.target.children[0].children[0]);
            // console.log(event.target.children[0].children[0].children[0]);
            // event.target.children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].style.display = "inline-block"
            // event.target.children[0].children[0].children[0].children[0].style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
            // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "inline-block"
          },
          mouseleave: (event) => {
            // console.log(event.target.className.indexOf('ant-table-row-selected'));
            // if (event.target.className.indexOf('ant-table-row-selected') < 0) {
            //   event.target.children[0].children[0].style.display = "none";
            //   // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "none"
            //   // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "none"

            // }

          }

        },
      }

    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 取消
    handleBatch(key, record, title = "删除") {
      let self = this
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: h =>
          <div>
            确定不允许商品【 <span class='baseColor11'  > {record.goods_name} </span>  】赠送吗？
          </div>
        ,
        okText: '确定',
        closable: true,
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          let data = {
            "goods_ids": [record.goods_id],
            "save_data": {
              "is_give": 0
            }
          }
          BatchGoods(data).then(res => {
            if (res.error_code == 0) {
              // self.$message.success('操作成功')
              self.getList()
              self.onfunsuccess(record.goods_name)
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    // 
    getListSearch(e) {
      console.log(e);
      if (e.activeStatus1.name == "商品名称") {
        this.query.goods_name = e.value
        this.query.goods_bn = undefined
        this.getList()
      } else {
        this.query.goods_name = undefined
        this.query.goods_bn = e.value
        this.getList()
      }
      // 整合e的value 和 e.activeStatus1对应的select值 调取 getList()
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          company_parent: self.query.company_parent,
          goods_type: ['rights_card', 'third_rights_card'],
          goods_name: this.query.goods_name,
          goods_bn: this.query.goods_bn,
          is_give: 1
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GetmanageGoodslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data)
        this.treeData = res.data
        console.log(this.treeData, '>>>this.coptionsList');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {

      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id === item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add === item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle !== 0) {
        this.permissionInfo.info.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          this.permissionInfo.info.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //  
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })
        //  
        console.log(arry, "mainarr....");
        // arry=[ {"颜色":"白色"}， {"颜色":"黑色"}，{"尺寸":"m"}，{"尺寸":"l"}  ]
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        mainarr.forEach(m => {
          let s = JSON.stringify(m.product_attr)
          m.product_attr = JSON.parse(s.replace(/},{/g, ","))
        })
        //  
        console.log(mainarr, "mainarr....");
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          mainarr.forEach((m, n) => {
            if (el.product_id == m.product_id || el.product_id_add == m.product_id_add) {
              el.product_attr = m.product_attr[0]
              for (let item in el.product_attr) {
                let names = `"${item}"`
                el.product_attr[names] = el.product_attr[item]
                delete el.product_attr[item]
              }
            }
          })
        })

        console.log(this.permissionInfo.info.goods_attr_val, " this.permissionInfo.info.goods_attr_val");
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at
          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        //  
        console.log(this.permissionInfo.info.goods_attr_val);

      } else {
        this.permissionInfo.info.goods_attr = []
        delete this.goodSpecsSingletableData[0].product_id
        delete this.goodSpecsSingletableData[0].product_id_add
        this.permissionInfo.info.goods_attr_val = this.goodSpecsSingletableData
      }
      delete this.permissionInfo.info.pic_url_loading

      // 区分新增 修改接口
      if (this.permissionInfo.type == 3) {
        delete this.permissionInfo.info.created_at
        delete this.permissionInfo.info.updated_at
        let data = {
          ...this.permissionInfo.info
        }

        UpdateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        //新增


        let data = {
          ...this.permissionInfo.info
        }
        delete data.product_id
        delete data.product_id_add
        CreateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        permission_id_lis: [],
        goods_type: ['rights_card', 'third_rights_card'],
        goods_category: undefined,//商品分类
        goods_brand: undefined,//商品品牌
        goods_bn: undefined,
        goods_id: undefined,
        goods_name: undefined,
        goods_supplier: undefined,
        goods_unit: undefined,// 单位
        is_show: 1,// 商品状态
        is_del: 0,//是否删除
        goods_image: undefined,
        goods_slider: undefined,//轮播图
        goods_attr: [],//商品规格
        goods_attr_val: [],//商品规格值 
        goods_content: undefined,//商品描述
        goods_desc: undefined,//商品简介
      }
      // await this.getCompanyoptionList(true)
      console.log(this.treeData);
      this.permissionInfo.visible = true

      if (i == 3) {
        this.pageStatus = 'add'
        this.permissionInfo.isDisabled = true
        this.permissionInfo.title = '编辑商品'
        this.addorupdateSpecFlag = 'update'
        this.permissionInfo.info.goods_id = e.goods_id
        this.permissionInfo.type = 3
        this.openDatails()
        console.log(this.permissionInfo.info);
        this.$router.replace({ path: '/shop/management/managementlist', query: { type: i, id: e.goods_id } })
      } else {
        this.pageStatus = 'add'
        this.permissionInfo.title = '添加商品'
        this.addorupdateSpecFlag = 'add'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.goods_id
        this.$router.replace({ path: '/shop/management/managementlist', query: { type: i } })
        console.log(this.permissionInfo);

      }
      Msg.$emit('changethirdaryShow', false)
    },

    openDatails() {
      GoodsInfo({ goods_id: this.permissionInfo.info.goods_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        this.permissionInfo.info.goods_type = this.permissionInfo.info.goods_type + ''
        this.permissionInfo.info.goods_brand = this.permissionInfo.info.goods_brand + ''
        this.permissionInfo.info.goods_supplier = this.permissionInfo.info.goods_supplier + ''
        this.permissionInfo.info.goods_slider = JSON.parse(this.permissionInfo.info.goods_slider)
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = idx
        })
        let fileList = []
        // 回显轮播图
        this.permissionInfo.info.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        this.$refs.uploadCom.fileList = fileList
        // 单规格 or 多规格
        if (this.permissionInfo.info.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }
            this.goods_specs.new_goods_specs_list.push(obj)
          })
          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          this.goods_specs_clomns = []
          let arri1 = []
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              scopedSlots: { customRender: el.attr_name },
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            this.goods_specs_clomns.push(obj)
            arri1.push(object1)
          })
          this.permissionInfo.info.goods_attr = arri1
          this.goods_specs_clomns = [... this.goods_specs_clomns, ...this.goods_specs__sigle_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          //  
          newResult.forEach((el, idx) => {
            let obj = {
              product_id: this.permissionInfo.info.goods_attr_val[idx].product_id,
              product_bn: this.permissionInfo.info.goods_attr_val[idx].product_bn,
              product_attr: this.permissionInfo.info.goods_attr_val[idx].product_attr,
              product_is_def: this.permissionInfo.info.goods_attr_val[idx].product_is_def,
              product_image: this.permissionInfo.info.goods_attr_val[idx].product_image,
              product_price: this.permissionInfo.info.goods_attr_val[idx].product_price,
              product_cost_price: this.permissionInfo.info.goods_attr_val[idx].product_cost_price,
              product_market_price: this.permissionInfo.info.goods_attr_val[idx].product_market_price,
              product_stock: this.permissionInfo.info.goods_attr_val[idx].product_stock
            }
            el = Object.assign(el, obj)
          })

          this.goodSpecstableData = newResult
          console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecsSingletableData = this.permissionInfo.info.goods_attr_val
        }

      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },
    bantchGiveup() {
      let self = this
      let a = []
      self.table.selectedRows.forEach(el => {
        a.push('【' + el.goods_name + "】")
      })
      a = a.join()
      // 
      console.log(a);
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title: '取消赠送',
        content: h =>
          <div>
            确定不允许商品
            <span class='baseColor11'>{a}</span>
            赠送吗？
          </div>
        ,
        okText: '确定',
        closable: true,
        okType: 'primary',
        cancelText: '取消',
        onOk() {

          let data = {
            "goods_ids": self.table.selectedRowKeys,
            "save_data": {
              "is_give": 0
            }
          }

          BatchGoods(data).then(res => {
            if (res.error_code == 0) {

              // self.$message.success('操作成功')//
              self.getList()
              self.onfunsuccess(a)
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });

    },
    onfunsuccess(a) {
      this.$warning({
        title: '提示',
        content: (
          <div>
            <span class='baseColor11'>{a}</span>
            已变更为普通商品，不允许赠送！
          </div>
        ),
      });
    },
    beforeUpload(file, fileList) {
      //  
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 商品头图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 规格图
    async uploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {
            if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
              el.product_image = res.data.url
            }
          })
        }


        // this.permissionInfo.info.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    hideModal() { },

    bantchGiveup() {
      let self = this
      let a = []
      self.table.selectedRows.forEach(el => {
        a.push('【' + el.goods_name + "】")
      })
      a = a.join()
      // 
      console.log(a);
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title: '取消赠送',
        content: h =>
          <div>
            确定不允许商品
            <span class='baseColor11'>{a}</span>
            赠送吗？
          </div>
        ,
        okText: '确定',
        closable: true,
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          let data = {
            "goods_ids": self.table.selectedRowKeys,
            "save_data": {
              "is_give": 0
            }
          }
          BatchGoods(data).then(res => {
            if (res.error_code == 0) {
              self.getList()
              self.onfunsuccess(a)
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });

    },







  },
  watch: {

  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 18px 12px 18px 18px;
  // margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
    height: 66px;
    display: flex;
    align-items: center;
    z-index: 1;
    .canclezs {
      display: inline-block;
      // width: 96px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-weight: 400;
      color: @baseColor11;
      background: #f0f5f4;
      border-radius: 4px;
      text-align: center;
      margin-right: 12px;
      padding: 0 12px;
    }
    .checkedZs {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: @fontColor5;
    }
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.baseColor11 {
  color: @baseColor11;
}
/deep/.bigdropdown .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 64px;
    font-size: 8px;
    margin-top: -1px;
    transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: scale(0.83) rotate(180deg);
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}

.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}

/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}

.guige_values_options {
  position: relative;
  margin-right: 20px;
  // .
  i {
    position: absolute;
    right: -8px;
    top: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.13);
  }
}
.closeIcon {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.13);
}
/deep/ .myFileList .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 40px;
}
/deep/ .guigeTable .ant-spin-nested-loading {
  min-height: 40px;
}
/deep/.ant-table-pagination.ant-pagination {
  height: 65px;
}

/deep/ .ant-table-header .ant-checkbox {
  width: 14px;
  height: 14px;
}
/deep/ .table-manage .ant-table-header .ant-checkbox-inner {
  // display: none;
}
/deep/.table-manage .ant-table-tbody .ant-checkbox-inner {
  // display: none;
}
/deep/
  .table-manage
  .ant-table-tbody
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  display: block !important;
}
/deep/ .table-manage .ant-table-selection-column:hover .ant-checkbox-inner {
  display: block !important;
}
/deep/.tableBox .ant-table-thead .ant-table-selection-column span:nth-child(1) {
  display: inline-block;
}
/deep/.tableBox .ant-table-body .ant-table-selection-column span:nth-child(1) {
  display: none;
}
.meiye-xiala {
  color: @fontColor4;
}

.ant-table-placeholder .baseColor11 {
  color: @baseColor11;
}
.ant-table-placeholder .meiye-leixing,
.meiye-zhuangtai,
.meiye-fenlei,
.meiye-pinpai {
  color: #9ea6a4;
  font-size: 16px;
}

/deep/ .ant-table-placeholder {
  border-top: none;
}
/deep/.ant-btn.smallbtn {
  letter-spacing: 0;
}
/deep/.ant-checkbox-inner::after {
  top: 50%;
}
/deep/ .table-manage .ant-table-thead > tr > th {
  margin-bottom: 0;
  padding-bottom: 6px !important;
}
</style>